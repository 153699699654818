"use client";

import NextError from "next/error";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { env } from "~/env.mjs";
process.env.NODE_ENV === "production" && Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0
});
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    if (error.digest) {
      console.error(error.digest);
    }
    console.error(error);
    Sentry.captureException(error, {
      tags: {
        digest: error.digest
      }
    });
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        <NextError statusCode={undefined as any} data-sentry-element="NextError" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}